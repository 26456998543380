/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import './variables/_variables.scss';

body {
  font-family: "Montserrat", $hh-font-family-emoji, "Helvetica", "Arial", "serif";
  color: #1b365c;
}
.password-advice {
  cursor: pointer;
}
.password-advice:hover .advice-item {
  background-color: #ffe9d5 !important;
  color: #ff9015 !important;
}

.is-error-input {
  border: 2px solid #A5173B !important;
  border-radius: 0.357rem;
}

p {
  margin-bottom: 0 !important;
}

.select__menu {
  z-index: 10000 !important;
}

.hh-txt {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.button-min-width {
  min-width: 160px !important;
}

.table th,
.table td {
  border-top: 1px solid transparent !important;
}

.table .thead-light th {
  border-color: transparent !important;
}

.container {
  padding-right: 0px;
  padding-left: 0px;
  margin-right: 0px;
  margin-left: 0px;
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  margin-top: 20px;
  height: auto;
  margin: auto;
  width: 100%;
}

.child {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.accept {
  border-color: #107c10 !important;
}

.reject {
  border-color: #d83b01 !important;
}

.ql-video {
  width: 100% !important;
  min-height: 320px;
  height: auto !important;
}

button.ql-video {
  height: 24px !important;
  min-height: 24px !important;
  width: 28px !important;
}
.ql-snow .ql-tooltip {
  border-radius: 4px;
  border: none !important;
  &.ql-editing input[type=text] {
    border-radius: 4px;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }

    &::placeholder {
      color: transparent !important;
    }
   
  }
  &.ql-editing a.ql-action::after {
    content: "Speichern" !important;
    color: #fd902d;
    font-weight: bold;
  }

}

.ql-tooltip[data-mode=link]::before {
  content: "Geben Sie den Link ein" !important;
  color: #5e5873;
}

.ql-tooltip[data-mode=video]::before {
  content: "Geben Sie den Link ein" !important;
  color: #5e5873;
}

.ql-editor {
  min-height: 12em;
  font-family: "Inter", $hh-font-family-emoji;
  color: #1b365c;
}

.ql-snow .ql-tooltip.ql-editing input[type=text]::placeholder {
  content: "https://www.beispiel.de";
}
.daily-set-rules {
  .ql-editor {
    min-height: 12em;
    font-family: "Inter", $hh-font-family-emoji;
    color: #3d555e !important;
  }

  .ql-snow .ql-editor h1 {
    font-size: 50px !important;
    line-height: 62.5px !important;
    color: #3d555e !important;
  }

  .ql-snow .ql-editor h2 {
    font-size: 36px !important;
    line-height: 48px !important;
    color: #3d555e !important;
  }

  .ql-snow .ql-editor h3 {
    font-size: 26px !important;
    line-height: 32px !important;
    color: #3d555e !important;
  }

  .ql-snow .ql-editor h4 {
    font-size: 22px !important;
    line-height: 30px !important;
    color: #3d555e !important;
  }

  .ql-snow .ql-editor h5 {
    font-size: 18px !important;
    line-height: 26px !important;
    color: #3d555e !important;
  }

  .ql-snow .ql-editor {
    font-size: 14px !important;
    line-height: 21px !important;
    color: #3d555e !important;
  }

  span,
  u,
  strong,
  em,
  i {
    color: #3d555e !important;
  }
}

.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

.input-group .container {
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.custom-modal-style {
  width: 1024px;
  height: 700px;
}

.description {
  display: block;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.diary-form {
  position: relative;
}

.add-section {
  position: fixed;
  bottom: 15px;
  right: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 25px;

  .add-button {
    background: #ffffff;
    box-shadow: 0px 8px 24px rgba(111, 111, 111, 0.2);
    border-radius: 8px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .icon {
      width: 24px;
      margin-right: 10px;
    }

    p {
      margin-bottom: 0px !important;
    }

    .text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;

      display: flex;
      align-items: center;

      color: #303030;
    }
  }
}

.absolute-actions {
  position: absolute;
  bottom: 0;
  right: 10px;
  background-color: #ffffff;
  width: 368px;

  .py-3 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .px-12 {
    padding-left: 48px;
    padding-right: 48px;
  }
}

.flex {
  display: flex !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-col {
  flex-direction: column !important;
}

.justify-between {
  justify-content: space-between !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.items-center {
  align-items: center !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.w-1-2 {
  width: 50%;
}

.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.relative {
  position: relative !important;
}

.mx-1 {
  margin-left: 4px;
  margin-right: 4px;
}

.mb-1 {
  margin-bottom: 4px;
}

.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.px-2 {
  padding-left: 8px;
  padding-right: 8px;
}

.p-2 {
  padding: 8px;
}

.pl-4 {
  padding-left: 16px;
}

.pl-3 {
  padding-left: 12px;
}

.w-full {
  width: 100% !important;
}

.h-full {
  height: 100% !important;
}

.max-w-sm {
  p {
    margin-bottom: 0 !important;
    margin-right: 4px;
  }

  .form-control {
    padding-top: 0 !important;
  }
}

.border-none {
  border: none !important;
  outline: none !important;
}

.border-none:focus {
  border: none !important;
  outline: none !important;
}

.dropzoneProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #8199a1;
  height: auto;
  margin: auto;
  width: 100%;
  padding: 25px 10px;
  margin-top: 25px;
  border-radius: 8px;
  background-color: #f9fafb;
  font-size: 16px;
}

.section {
  height: 100%;

  .section-label-text {
    position: static;

    font-family: "Inter", $hh-font-family-emoji;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #1b365c !important;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .section-quote-text {
    position: static;
    font-family: "Inter", $hh-font-family-emoji;
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #1b365c !important;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .section-content-text {
    position: static;
    font-family: "Inter", $hh-font-family-emoji;
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    display: flex;
    align-items: center;
    color: rgba(48, 48, 48, 0.7) !important;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

.default-template-button {
  width: 235px;
  height: 56px;

  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(111, 111, 111, 0.2);
  border-radius: 8px;

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .text {
    width: 167px;
    height: 21px;

    /* Desktop/Body/Body Small (Med) */

    font-family: "Inter", $hh-font-family-emoji;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;

    color: #303030;
  }
}

.go-back-button {
  width: 152px;
  height: 56px;
  left: 730px;
  top: 896px;

  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(111, 111, 111, 0.2);
  border-radius: 8px;

  .text {
    width: 56px;
    height: 21px;
    left: 48px;
    top: 18px;

    /* Desktop/Body/Body Small (Med) */

    font-family: "Inter", $hh-font-family-emoji;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;

    color: #303030;
  }
}

.use-template-button {
  width: 188px;
  height: 56px;
  left: 906px;
  top: 896px;

  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(111, 111, 111, 0.2);
  border-radius: 8px;

  .text {
    width: 92px;
    height: 21px;
    left: 48px;
    top: 18px;

    /* Desktop/Body/Body Small (Med) */

    font-family: "Inter", $hh-font-family-emoji;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;

    color: #303030;
  }
}

.highlighted-text {
  color: #e48229;
}

.section-questions {
  [type="checkbox"]:checked,
  [type="radio"]:checked {
    border-color: transparent;
    background-color: #1b365cb2;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.grow-wrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}

.grow-wrap::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}

.grow-wrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}

.grow-wrap > textarea,
.grow-wrap::after {
  /* Identical styling required!! */
  // border: 1px solid black;
  // padding: 0.5rem;
  font: inherit;
  text-align: justify;
  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

.trash-emoji {
  display: none;
}

.emoji-side {
  padding: 10px 0;
}

.emoji-side:hover {
  background: rgba(48, 48, 48, 0.05);
  border-radius: 8px;
}

.emoji-side:hover .trash-emoji {
  display: block;
}

.top-1 {
  top: 1px !important;
}

.right-6 {
  right: 24px !important;
}

.border {
  border: 1px solid grey !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.w-4 {
  width: 16px !important;
}

.h-4 {
  height: 16px !important;
}

.rounded-full {
  border-radius: 100% !important;
}

@font-face {
  font-family: "CaliforniaDreamer-Sans";
  src: url("../fonts/CaliforniaDreamerSans.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Cormorant";
  src: local("Cormorant"),
    url("../fonts/CormorantInfantRegular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("../fonts/InterRegular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Noto Color Emoji";
  src: local("Noto Color Emoji"),
    url("../fonts/NotoColorEmoji-Regular.ttf") format("truetype");
  font-display: swap;
}

.basic-multi-async-select .select__multi-value {
  background-color: #ebe9f1 !important;
  margin: 0.25rem 0.7rem 0.25rem 0 !important;
}

#content > .ql-toolbar {
  border: none !important;
  background: #fefcfc !important;
  align-self: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

#content > .ql-container.ql-snow {
  border: none !important;
  background: #fefcfc !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.react-quill-custom-image-style {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.ql-editor {
  font-family: "Inter", $hh-font-family-emoji !important;
  color: #1b365c !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.faq-response .ql-editor {
  font-family: "Inter", $hh-font-family-emoji !important;
  color: #6e6b7b !important;
  font-size: inherit !important;
  line-height: inherit !important;
}

.faq-response .ql-tooltip {
  left: 10px !important;
}

.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;

    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font {
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font .ql-picker-label[data-value="Cormorant"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Cormorant"]::before {
  font-family: "Cormorant", cursive;
  content: "Cormorant" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Inter"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Inter"]::before {
  font-family: "Inter", cursive;
  content: "Inter" !important;
}

.ql-font-Cormorant {
  font-family: "Cormorant", $hh-font-family-emoji;
}

.ql-font-Inter {
  font-family: "Inter", $hh-font-family-emoji;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Durchsuche";
}

.disabled-dropdown-item {
  cursor: default;

  &:hover {
    background-color: transparent !important;
  }

  margin-right: 5px;
}

.live-events-table .rdt_TableRow .rdt_TableCell:last-child {
  justify-content: center;
}

.text-blue {
  color: #1b365c !important;
}

.swal2-content {
  color: #1b365c !important;
}

#articleContentReactQuill {
  width: 720px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before {
  content: "H1" !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before {
  content: "H2" !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before {
  content: "H3" !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before {
  content: "H4" !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before {
  content: "H5" !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "H1" !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "H2" !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "H3" !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "H4" !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "H5" !important;
}

.ql-snow .ql-editor h1 {
  font-size: 50px !important;
  line-height: 62.5px !important;
  color: #1b365c;
}

.ql-snow .ql-editor h2 {
  font-size: 36px !important;
  line-height: 48px !important;
  color: #1b365c;
}

.ql-snow .ql-editor h3 {
  font-size: 26px !important;
  line-height: 32px !important;
  color: #1b365c;
}

.ql-snow .ql-editor h4 {
  font-size: 22px !important;
  line-height: 30px !important;
  color: #1b365c;
}

.ql-snow .ql-editor h5 {
  font-size: 18px !important;
  line-height: 26px !important;
  color: #1b365c;
}

.ql-snow .ql-editor normal {
  font-size: 16px !important;
  line-height: 24px !important;
  color: #1b365c;
}

.ql-snow .ql-editor blockquote {
  font-size: 22px !important;
  line-height: 30px !important;
  color: #ff9015 !important;
  border-left: 2px solid #ff9015 !important;
  padding-left: 24px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.quillEditor {
  font-family: "Inter", $hh-font-family-emoji;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #1b365c;

  h1 {
    font-size: 50px !important;
    line-height: 62.5px !important;
    color: #1b365c;
  }

  h2 {
    font-size: 36px !important;
    line-height: 48px !important;
    color: #1b365c;
  }

  h3 {
    font-size: 26px !important;
    line-height: 32px !important;
    color: #1b365c;
  }

  h4 {
    font-size: 22px !important;
    line-height: 30px !important;
    color: #1b365c;
  }

  h5 {
    font-size: 18px !important;
    line-height: 26px !important;
    color: #1b365c;
  }

  blockquote {
    font-size: 22px !important;
    line-height: 30px !important;
    color: #ff9015 !important;
    border-left: 2px solid #ff9015 !important;
    padding-left: 24px;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  a {
    text-decoration: underline;
  }

  code {
    background-color: #f0f0f0;
    border-radius: 3px;
  }

  p {
    font-size: 16px !important;
    line-height: 24px !important;
    color: #1b365c;
  }

  ol {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
      list-9;
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    padding-left: 1.5em;
  }

  ul {
    padding-left: 1.5em;
  }

  li {
    font-size: 16px !important;
    line-height: 24px !important;
    color: #1b365c;
  }

  div {
    font-size: 16px !important;
    line-height: 24px !important;
    color: #1b365c;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  a {
    color: #06c;
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

#custom-input {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  display: block;
  width: 100%;
  height: 2.714rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#custom-input:focus {
  border-color: #fd902d;
  // box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  outline: 0;
}

#custom-input::-webkit-input-placeholder {
  color: #6e6b7b;
  opacity: 1;
}

#custom-input::-moz-placeholder {
  color: #6e6b7b;
  opacity: 1;
}

#custom-input:-ms-input-placeholder {
  color: #6e6b7b;
  opacity: 1;
}

#custom-input::-ms-input-placeholder {
  color: #6e6b7b;
  opacity: 1;
}

#custom-input::placeholder {
  color: #6e6b7b;
  opacity: 1;
}

.save-button-hover:hover {
  text-decoration: underline;
  color: orange;
}

.delete-button-hover:hover {
  text-decoration: underline;
  color: red;
}

.edit-button-hover:hover {
  text-decoration: underline;
  color: orange;
}

.link-hover:hover {
  text-decoration: underline;
}

label {
  font-size: 16px;
  font-weight: bold;
}

.mod-title {
  font-family: "Inter", $hh-font-family-emoji;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #1b365c;
  margin-bottom: 8px;
}

.mod-description {
  font-family: "Inter", $hh-font-family-emoji;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #8199a1;
}

.save-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ffe9d5;
  border-radius: 52px;
  font-family: "Inter";
  font-style: normal;
}

.save-button-text {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #fd902d;
  font-family: "Inter";
  font-style: normal;
}

.cancel-button {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #8199a1;
  font-family: "Inter";
  font-style: normal;
}

.download-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 123px;
  height: 32px;
  background: #e8e8e9;
  border-radius: 52px;
}

.download-button-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #1b365c;
}

.download-contain {
  display: flex;
  align-items: center;
}

.modal-content {
  min-width: 618px !important;
}

.modal-body {
  min-width: 618px !important;
}

.chatTextarea__input {
  width: 100% !important;
  height: 100% !important;
  background-color: white !important;
  border-style: none !important;
  outline: none !important;
  resize: none !important;
  overflow: auto !important;
  font-family: "Inter", $hh-font-family-emoji, "sans-serif" !important;

  &:hover {
    outline: none !important;
    border-style: none !important;
  }

  &:focus {
    outline: none !important;
    border-style: none !important;
    overscroll-behavior: contain;
  }
}

.chatTextarea__highlighter {
  height: 100% !important;
  color: transparent !important;
}

.tdm-pre-custom-class {
  font-size: inherit !important;
  font-weight: inherit !important;
  font-family: inherit !important;
  color: inherit !important;
  background-color: inherit !important;
  margin: inherit !important;
}

.edit-pencil {
  position: absolute;
  top: 0px;
  right: 0px;
}

.role-td {
  padding: 0.72rem 0px !important;
}

.my-swal-title {
  font-size: 20px !important;
}

.my-swal-content {
  font-size: 16px !important;
}

.my-swal-icon {
  width: 32px !important;
  height: 32px !important;
}

.swal2-icon-content {
  font-size: 32px !important;
}

.hover-section-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 50;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
}

.section-pic-modal .modal-content {
  min-width: 538px !important;
  overflow-y: auto;
}

.section-pic-modal .modal-body {
  min-width: 538px !important;
  background-color: #ffffff;
  padding: 0;
  border-radius: 8px;
  overflow-y: auto;
}

.section-clear-modal .modal-content {
  min-width: 367px !important;
  width: 368px !important;
}

.section-clear-modal .modal-body {
  min-width: 367px !important;
  width: 368px !important;
  background-color: #ffffff;
  padding: 0;
  border-radius: 8px !important;
  box-shadow: 0px 8px 24px 0px rgba(111, 111, 111, 0.2) !important;
}

.emoji-mart-category-label span {
  color: #1b365c !important;
  font-size: 13px !important;
}

.emoji-mart .search input[type="search"] {
  color: #1b365c !important;
  font-size: 13px !important;

  background: #ffffff !important;
  box-shadow: 0px 0px 24px rgba(231, 224, 211, 0.4) !important;
  border-radius: 8px !important;
  border-color: #8199a1 !important;

  &::placeholder {
    color: #8199a1 !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.emoji-mart-anchors svg {
  width: 14px !important;
  height: 14px !important;
}

.emoji-mart-category .emoji-mart-emoji span {
  width: 24px !important;
  height: 24px !important;
}

@media screen and (max-width: 768px) {
  .emoji-mart {
    width: 100% !important;
  }

  .emoji-mart-category .emoji-mart-emoji span {
    width: 20px !important;
    height: 20px !important;
  }

  .emoji-mart-scroll {
    height: 270px !important;
  }
}

.emoji-font-family {
  font-family: $hh-font-family-emoji;
}
